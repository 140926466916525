export const formatUrlToUpperCamelCase = (url: string) => {
  const parts = url.split('-');
  const capitalizedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1)
  );
  const camelCase = capitalizedParts.join('');
  return camelCase;
};

export const reemplazarTodo = (texto: string, buscar: string, reemplazar:string) => {
  return texto.replace(new RegExp(buscar, 'g'), reemplazar);
};
