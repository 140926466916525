import { environment } from "@environments/environment";

export const URL_MARCA: string = environment.UrlMarca;
export const URL_MODELO: string = environment.UrlModelo;
export const URL_MARCACONVENIO: string = environment.UrlMarcaConvenio;
export const URL_MODELOCONVENIO: string = environment.UrlModeloConvenio;
export const URL_DATOS_VEHICULO: string = environment.UrlDatosVehiculo;
export const URL_VALIDACION_PATENTE: string = environment.UrlValidacionPatente;
export const URL_CLIENTE: string = environment.UrlCliente;
export const URL_REGION: string = environment.UrlRegion;
export const URL_COMUNA: string = environment.UrlComuna;
export const URL_DATOS_PAGO: string = environment.UrlDatosPago;
export const URL_TARIFA: string = environment.UrlTarifa;
export const URL_RETARIFA: string = environment.UrlRetarifa;
export const URL_CONVENIO: string = environment.UrlConvenio;
export const URL_COTIZADOR: string = environment.UrlCotizacion;
export const URL_DOCUMENTO_COTIZACION: string = environment.UrlDocumentoCotizacion;
export const URL_PROPUESTA: string = environment.UrlPropuesta;
export const URL_INTENCION: string = environment.UrlIntencion;
export const URL_POLIZA: string = environment.UrlPoliza;
export const URL_PINES: string = environment.UrlPines;
export const URL_MANDATO: string = environment.UrlMandato;
export const URL_TOKEN: string = environment.UrlToken;
export const URL_GPS: string = environment.UrlGps;