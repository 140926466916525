export class EstadosCotizacionEnums {
  public static NUEVO: number = 0;
  public static INGRESO_ASEGURADO: number = 1;
  public static INGRESO_VEHICULO: number = 2;
  public static INGRESO_TARIFAS: number = 3;
  public static INGRESO_CONTRATANTE: number = 4;
  public static INGRESO_FORMA_PAGO: number = 5;
  public static LINK_PAGO: number = 6;
  public static GENERACION_POLIZA: number = 7;
  public static INGRESO_PROPUESTA: number = 8;
}

export class TipoArchivoEnums {
  public static FACTURA: number = 1;
  public static COTIZACION: number = 2;
  public static PROPUESTA: number = 3;
  public static POLIZA: number = 4;
}

export class SistemaEnums {
  public static VENTA_ASISTIDA: number = 1;
}

export class TipoRelacionPersonaEnums {
  public static CONTRATANTE: number = 2;
  public static ASEGURADO: number = 3;
  public static EJECUTIVO: number = 5;
}

export class FormaPagoEnum {
  public static SUSCRIPCION: number = 2;
  public static CONTADO: number = 3;
  public static AVISO: number = 4;
}
