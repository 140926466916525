import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encriptarParametrosUrl } from '@utils/encript.util';
import { URL_INTENCION } from '@utils/url.util';

@Injectable({
  providedIn: 'root'
})
export class IntencionService {

  constructor(private httpClient: HttpClient) { }

  async enviarLink(cotizacionId: string, correo: string): Promise<void> {
    this.httpClient.post(URL_INTENCION+"/EnviarLink", { parametro: cotizacionId.toString(), correo},{ responseType: 'text' }).toPromise();
  }

  async obtenerUrlIntencion(cotizacionId: string): Promise<any> {
    return  this.httpClient.post<any>(URL_INTENCION+"/GenerarIntencion", { parametro: cotizacionId.toString()}).toPromise();
  }
}
