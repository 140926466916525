import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encriptarParametrosUrl } from '@utils/encript.util';
import { URL_GPS, URL_INTENCION } from '@utils/url.util';

@Injectable({
  providedIn: 'root'
})
export class GpsService {

  constructor(private httpClient: HttpClient) { }

  async obtenerDatosGps(cotizacionId: any): Promise<any> {
    let params = new HttpParams()
      .set('parametro', cotizacionId.toString())

    return this.httpClient.get<any>(URL_GPS + '/ObtenerGpsPorCotizacionId', { params }).toPromise();
  }
}
