import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encriptarParametrosUrl } from '@utils/encript.util';
import { URL_POLIZA } from '@utils/url.util';
import { ComunService } from './comun.service';

@Injectable({
  providedIn: 'root'
})
export class PolizaService {

  constructor(private httpClient: HttpClient, private comunService: ComunService) { }

  public async generarPoliza(parametro: string): Promise<any> {
    return this.httpClient.post<any>(URL_POLIZA+'/EmisionSuscripcion', {parametro}).toPromise();
  }

  public async generarDirectaPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient.post(URL_POLIZA+'/EmisionDirecta',{parametro: numeroPoliza}).toPromise();
  }

  public async generarMandatoPoliza(cotizacionId: string, idMandato?: string, rutContratante?: string): Promise<any> {
    return this.httpClient.post(URL_POLIZA + '/EmisionMandato', { cotizacionId, idMandato, rutContratante }).toPromise();
  }

  public async obtenerArchivoPoliza(numeroPoliza: string): Promise<any> {
    return this.httpClient.post(URL_POLIZA + '/ObtenerPdf', { parametro: numeroPoliza.toString() }, { responseType: 'blob' }).toPromise();
  }
}
