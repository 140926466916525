export const environment = {
  production: true,
  URL_API: 'http://localhost:4200/assets/datos/usuarios.json',
  cryptoKey: '%D*F-JaNdRgUkXp2s5v8y/B?E(H+KbPe',
  cryptoKeyContinuarCotizacion: 'UkXp2s5v8y/B?E(H+MbQeThVmYq3t6w9',
  CantidadCuotas: 12,
  IdApp: 47, //46, //QA //47 PROD
  UrlBase: 'https://apigwp.bciseguros.cl:8443/Apiventaasistida', //'https://apigw.qa.bciseguros.cl:8443/Apiventaasistida' //QA //'https://apigwp.bciseguros.cl:8443/Apiventaasistida', //prod
  UrlMarca: '/Marca', //ok
  UrlModelo: '/modelo', //ok
  UrlMarcaConvenio: '/marca/DirectoConvenio', //ok
  UrlModeloConvenio: '/modelo/DirectoConvenio',
  UrlDatosVehiculo: '/Vehiculo', //ok
  UrlValidacionPatente: '/Vehiculo', //ok
  UrlCliente: '/DatosPersona',
  UrlRegion: '/Region', //ok
  UrlComuna: '/Comuna', //ok
  UrlDatosPago: '/DatosPago', //ok
  UrlTarifa: '/Tarificacion',
  UrlRetarifa: '/reTarificacion',
  UrlConvenio: '/convenio',
  UrlCotizacion: '/cotizacion',
  UrlDocumentoCotizacion: '/documento',
  UrlPropuesta: '/propuesta',
  UrlIntencion: '/intencion',
  UrlPoliza: '/Poliza',
  UrlPines: '/Pines',
  UrlMandato: '/Mandato',
  UrlToken: '/Login',
  UrlGps: '/Gps',
  recaptchaActions: {
    Step1: 'datos_generales_inicio',
    Step2: 'datos_generales_cotizacion',
    Step3: 'datos_generales_datos_contratante',
  },
  recaptchaLabels: {
    ErrorTitle: 'Error de verificación',
    ErrorSubtitle: 'Posible actividad sospechosa detectada'
  },
  SistemaPin:'prueba'
}; //NOSONAR
