import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { CotizacionModel, PersonaModel } from '@model/cotizacion-model';
import { ConvenioService } from '@services/convenio.service';
import { Store } from '@services/store.service';
import {
  desencriptarBase64_16,
  encriptarParametrosUrl,
} from '@utils/encript.util';
import { FormularioNuevo } from '@mocks/formulario';
import {
  EstadosCotizacionEnums,
  TipoRelacionPersonaEnums,
} from '@enums/estado-cotizacion.enum';
import { format } from '@utils/rut.util';
import { environment } from '@environments/environment';
import { LoginService } from '@services/login.service';

@Component({
  selector: 'app-inicio-cotizador',
  templateUrl: './inicio-cotizador.component.html',
  styleUrls: ['./inicio-cotizador.component.css'],
})
export class InicioCotizadorComponent implements OnInit {
  puntitos: string = '.';

  constructor(
    private route: ActivatedRoute,
    private convenioService: ConvenioService,
    private store: Store<SeguroStore>,
    private router: Router,
    private loginService: LoginService
  ) {}

  async ngOnInit() {
    setInterval(() => this.crearPuntitos(), 1000);

    let codificado: string = '';
    let nombreEjecutivo: string = '';
    let rutEjecutivo: string = '';
    let rutEjecutivoDv: string = '';
    let rutAsegurado: string = '';
    let rutAseguradoDv: string = '';
    let patente: string = '';
    let convenioId: string = '';
    let email: string = '';
    let telefono: string = '';
    let idGestorOp: string = '';

    this.route.queryParams.subscribe((params) => {
      if (params.go) {
        convenioId = desencriptarBase64_16(params.z);
        nombreEjecutivo = desencriptarBase64_16(params.ne);
        rutEjecutivo = desencriptarBase64_16(params.re);
        rutEjecutivoDv = desencriptarBase64_16(params.rd);
        rutAsegurado = desencriptarBase64_16(params.r);
        rutAseguradoDv = desencriptarBase64_16(params.d);
        patente = desencriptarBase64_16(params.p);
      } else {
        rutAsegurado = desencriptarBase64_16(params.r);
        rutAseguradoDv = desencriptarBase64_16(params.d);
        patente = desencriptarBase64_16(params.p);
        idGestorOp = desencriptarBase64_16(params.i);
        telefono = desencriptarBase64_16(params.t);
        email = desencriptarBase64_16(params.c);
        convenioId = desencriptarBase64_16(params.z);
        nombreEjecutivo = desencriptarBase64_16(params.ne);
        rutEjecutivo = desencriptarBase64_16(params.re);
        rutEjecutivoDv = desencriptarBase64_16(params.rd);
      }
    });
    let currentURL = window.location.href;

    try {
      // this.store.clearState();
      let state = this.store.getState();

      // let resultadoToken = await this.loginService.obtenerToken();
      // this.store.setState({
      //   ...state,
      //   token: resultadoToken.token,
      // });
      //state = this.store.getState();

      let convenio = await this.convenioService.obtenerConvenioPorId(
        convenioId
      );
      let ejecutivoId = 0;
      let cotizacion = new CotizacionModel();
      cotizacion.ConvenioId = parseInt(convenioId);
      cotizacion.Sucursal = convenio.sucursal;
      cotizacion.EjecutivoId = ejecutivoId;
      cotizacion.EstadoCotizacion = EstadosCotizacionEnums.NUEVO;

      cotizacion.Ejecutivo = new PersonaModel();
      cotizacion.Ejecutivo.Rut = rutEjecutivo;
      cotizacion.Ejecutivo.Dv = rutEjecutivoDv;
      cotizacion.Ejecutivo.Nombres = nombreEjecutivo;
      cotizacion.Ejecutivo.TipoRelacion = TipoRelacionPersonaEnums.EJECUTIVO;
      cotizacion.IdApp = environment.IdApp;
      cotizacion.DiasVigencia = convenio.diasVigencia;

      FormularioNuevo.aseguradoForm.datosPersonales.rut = format(
        rutAsegurado.replace('-', ''),
        { dots: true }
      );
      FormularioNuevo.vehiculoForm.datosVehiculo.patente = patente;

      if (idGestorOp) {
        FormularioNuevo.aseguradoForm.datosContacto.correo = email;
        FormularioNuevo.aseguradoForm.datosContacto.telefono =
          telefono.substr(2);
        FormularioNuevo.aseguradoForm.datosContacto.celular =
          telefono.substr(2);

        cotizacion.IdGestorOp = idGestorOp;
      }

      if (convenio.enableRecaptchaEnterprise) {
        let script: HTMLScriptElement | null = document.querySelector(
          'script[src*="https://www.google.com/recaptcha/enterprise.js"]'
        );
        if (!script) {
          script = document.createElement('script');
          script.src =
            'https://www.google.com/recaptcha/enterprise.js?render=' +
            convenio.reCaptchaEnterprisePublicKey;
          script.async = false;
          document.head.appendChild(script);
        }
      }

      this.store.setState({
        ...state,
        datosConvenio: convenio,
        datosCotizacion: cotizacion,
        formulario: FormularioNuevo,
        urlBase: currentURL,
        continuaCotizacion: false,
        googleTagManagerHead: convenio.tagManagerHead ?? '',
        googleTagManagerBody: convenio.tagManagerBody ?? '',
        mostrarMandato: convenio.mostrarMandato ?? false,
      });

      setTimeout(() => this.router.navigateByUrl('/datos-asegurado'), 1000);
    } catch (e) {
      console.log(e);
      this.redirectError();
    }
  }

  crearPuntitos() {
    this.puntitos = this.puntitos + '.';

    if (this.puntitos.length > 4) this.puntitos = '';
  }

  redirectError() {
    let url =
      '/error?t=' +
      encriptarParametrosUrl('Error') +
      '&m=' +
      encriptarParametrosUrl('Intentelo mas tarde');
    this.router.navigateByUrl(url);
  }
}
