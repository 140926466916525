import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { encriptarParametrosUrl } from "@utils/encript.util";
import { URL_PROPUESTA } from "@utils/url.util";
import { ComunService } from "./comun.service";

@Injectable({
  providedIn: "root",
})
export class PropuestaService {
  constructor(private httpClient: HttpClient) {}

  async cambiarEstadoCotizacion(cotizacionId: number): Promise<any> {
    let parametro = "/" + encriptarParametrosUrl(cotizacionId.toString());

    return this.httpClient
      .post<any>(URL_PROPUESTA + parametro, null)
      .toPromise();
  }

  async obtenerArchivoPropuesta(cotizacionId: string): Promise<any> {
    let params = new HttpParams().set("id", cotizacionId.toString());
    return this.httpClient
      .get(URL_PROPUESTA + "/PropuestaVentaAsistida/", {
        responseType: "blob",
        params,
      })
      .toPromise();
  }

  async obtenerIdPropuestaPorIdCotizacion(cotizacionId: string): Promise<any> {
    return this.httpClient
      .get(URL_PROPUESTA + "/ObtenerIdPropuestaPorIdCotizacionVentaAsistida", {
        params: {
          idCotizacion: cotizacionId.toString(),
        },
      })
      .toPromise();
  }
}
