import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Step } from '../components/shared/steps/step.interface';
import { StepIndex } from '@components/shared/steps/step-index.interface';

@Injectable({ providedIn: 'root' })
export class StepService {
  private stepSubject$ = new BehaviorSubject<Step[]>([]);
  private steps: Step[] = [];

  constructor() {}

  private getCurrentActiveStepIndex(): number {
    return this.steps.findIndex((step) => step.active);
  }

  init(steps: Step[]): void {
    this.steps = steps;
    this.stepSubject$.next(this.steps);
  }

  resetAll(): void {
    this.steps.forEach((step) => {
      step.active = false;
      step.completed = false;
      step.progressValue = 0;
    });
    this.stepSubject$.next(this.steps);
  }

  markAsCompletedCurrentActiveStep(): void {
    const currentActiveStep = this.getCurrentActiveStepIndex();
    this.steps[currentActiveStep].completed = true;
    this.steps[currentActiveStep].progressValue = 100;

    this.stepSubject$.next(this.steps);
  }

  markAsUncompletedCurrentActiveStep(): void {
    const currentActiveStep = this.getCurrentActiveStepIndex();
    this.steps[currentActiveStep].completed = false;
    this.steps[currentActiveStep].progressValue = 0;

    this.stepSubject$.next(this.steps);
  }

  markAllAsCompletedUntil(index: number): void {
    this.steps.forEach((step, i) => {
      if (i <= index) {
        step.completed = true;
        step.progressValue = 100;
        step.active = false;
      }
    });
    this.stepSubject$.next(this.steps);
  }

  markStepAsActive(index: number): void {
    this.steps.forEach((step, i) => {
      if (i === index) {
        step.active = true;
      }
    });
    this.stepSubject$.next(this.steps);
  }

  updateProgressValue(value: number): void {
    const currentActiveStep = this.getCurrentActiveStepIndex();
    this.steps[currentActiveStep].progressValue = value;
    this.stepSubject$.next(this.steps);
  }

  get getSteps$(): Observable<Step[]> {
    return this.stepSubject$.asObservable();
  }

  get getActiveStep$(): Observable<StepIndex | undefined> {
    return this.stepSubject$.asObservable().pipe(
      map((steps) => {
        let step = steps.find((step) => step.active && !step.completed) as Step;

        if (!step) {
          step = steps.find((step) => step.active && step.completed) as Step;
        }

        const indexOfStep = steps.findIndex(
          (stepRequest) => stepRequest === step
        );
        return {
          ...step,
          index: indexOfStep + 1,
        };
      })
    );
  }
}
