import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DatosConvenio,
  DatosPin,
  SeguroStore,
} from "@interfaces/seguro-store.interface";
import {
  CotizacionModel,
  FormaPagoModel,
  PersonaModel,
  VehiculoModel,
} from "@model/cotizacion-model";
import { ConvenioService } from "@services/convenio.service";
import { Store } from "@services/store.service";
import { encriptarParametrosUrl } from "@utils/encript.util";
import { FormularioNuevo } from "@mocks/formulario";
import {
  EstadosCotizacionEnums,
  TipoRelacionPersonaEnums,
} from "@enums/estado-cotizacion.enum";
import { format } from "@utils/rut.util";
import { CotizacionService } from "@services/cotizacion.service";
import { tieneValor } from "@utils/boolean.util";
import { DesplegableResumenService } from "@services/desplegable-resumen.service";
import { ComunService } from "@services/comun.service";
import { environment } from "@environments/environment";
import { reemplazarTodo } from "@utils/string.util";
import { LoginService } from "@services/login.service";

@Component({
  selector: "app-inicio-continuar-cotizador",
  templateUrl: "./inicio-continuar-cotizador.component.html",
  styleUrls: ["./inicio-continuar-cotizador.component.css"],
})
export class InicioContinuarCotizadorComponent implements OnInit {
  cotizacionId = "";
  nombreEjecutivo: string = "";
  rutEjecutivo: string = "";
  rutEjecutivoDv: string = "";
  convenioId = 0;

  constructor(
    private route: ActivatedRoute,
    private desplegableResumenService: DesplegableResumenService,
    private cotizacionService: CotizacionService,
    private convenioService: ConvenioService,
    private store: Store<SeguroStore>,
    private comunService: ComunService,
    private router: Router,
    private loginService: LoginService
  ) {}

  async ngOnInit() {
    this.procesarQueryParams();
    const state = await this.agregarTokenAlState();

    let datosCotizacion = await this.cotizacionService.obtenerCotizacion(
      this.cotizacionId
    );
    if (!datosCotizacion.continuable) {
      this.redirectError(datosCotizacion.mensajeNoContinuable);
    } else {
      let convenio = await this.convenioService.obtenerConvenioPorId(
        this.convenioId.toString()
      );
      
      const cotizacion = this.llenarObjetoCotizacion(datosCotizacion, convenio);
      const datosPin = new DatosPin();

      if (
        tieneValor(datosCotizacion.nroPin) &&
        tieneValor(datosCotizacion.descuento)
      ) {
        datosPin.descuento = datosCotizacion.descuento;
        datosPin.nroPin = datosCotizacion.nroPin;
      }

      this.setearStoreConDatos(state, convenio, cotizacion, datosPin);
      this.initDesplegableResumen();
      this.desplegableResumenService.editarNumeroCotizacion(this.cotizacionId);
      this.agregarAseguradoItemResumen();
      this.agregarVehiculoItemResumen(
        datosCotizacion.marcaDescripcion,
        datosCotizacion.modeloDescripcion,
        datosCotizacion.anio
      );
      this.hacerRedireccionSegunEstado(datosCotizacion, cotizacion);
    }
  }

  hacerRedireccionSegunEstado(
    datosCotizacion: any,
    cotizacion: CotizacionModel
  ) {
    if (datosCotizacion.estadoCotizacion == EstadosCotizacionEnums.LINK_PAGO) {
      cotizacion.EstadoCotizacion = EstadosCotizacionEnums.LINK_PAGO;
      setTimeout(() => this.router.navigateByUrl("/link-pago"), 1000);
    } else if (
      datosCotizacion.estadoCotizacion ==
      EstadosCotizacionEnums.INGRESO_PROPUESTA
    ) {
      cotizacion.EstadoCotizacion = EstadosCotizacionEnums.INGRESO_PROPUESTA;
      setTimeout(() => this.router.navigateByUrl("/link-pago"), 1000);
    } else if (
      datosCotizacion.estadoCotizacion ==
      EstadosCotizacionEnums.GENERACION_POLIZA
    ) {
      cotizacion.EstadoCotizacion = EstadosCotizacionEnums.GENERACION_POLIZA;
      setTimeout(
        () => this.router.navigateByUrl("/exito-emision-directa"),
        1000
      );
    } else {
      cotizacion.EstadoCotizacion = EstadosCotizacionEnums.INGRESO_TARIFAS;
      setTimeout(() => this.router.navigateByUrl("/planes"), 1000);
    }
  }

  setearStoreConDatos(
    state: SeguroStore,
    convenio: DatosConvenio,
    cotizacion: any,
    datosPin: DatosPin
  ) {
    const currentURL = window.location.href;
    this.store.setState({
      ...state,
      datosConvenio: convenio,
      datosCotizacion: cotizacion,
      formulario: FormularioNuevo,
      urlBase: currentURL,
      datosPin: datosPin,
      continuaCotizacion: true,
      googleTagManagerHead: convenio.tagManagerHead ?? "",
      googleTagManagerBody: convenio.tagManagerBody ?? "",
      mostrarMandato: convenio.mostrarMandato ?? false,
      validarChasisSinPatronesRepetidos:
        convenio.validarChasisSinPatronesRepetidos ?? false,
      validarMotorSinPatronesRepetidos:
        convenio.validarMotorSinPatronesRepetidos ?? false,
      productoAdicional: convenio.productoAdicional ?? null,
    });
  }

  llenarObjetoCotizacion(
    datosCotizacion: any,
    convenio: DatosConvenio
  ): CotizacionModel {

    const cotizacion = new CotizacionModel();
    cotizacion.ConvenioId = this.convenioId;
    cotizacion.Sucursal = datosCotizacion.sucursal;
    cotizacion.EjecutivoId = parseInt(this.rutEjecutivo);
    cotizacion.id = datosCotizacion.id;
    cotizacion.IdCotizacion = datosCotizacion.idCotizacion;
    cotizacion.EstadoCotizacion = EstadosCotizacionEnums.INGRESO_TARIFAS;
    cotizacion.IdApp = datosCotizacion.idApp;
    cotizacion.Ejecutivo = new PersonaModel();
    cotizacion.Ejecutivo.Rut = this.rutEjecutivo;
    cotizacion.Ejecutivo.Dv = this.rutEjecutivoDv;
    cotizacion.Ejecutivo.Nombres = this.nombreEjecutivo;
    cotizacion.Ejecutivo.TipoRelacion = TipoRelacionPersonaEnums.EJECUTIVO;
    cotizacion.DiasVigencia = convenio.diasVigencia;

    cotizacion.ramos = convenio.ramos;

    this.llenarDatosFormulario(datosCotizacion);
    cotizacion.Vehiculo = this.llenarDatosVehiculo(datosCotizacion);
    cotizacion.FormaPago = this.llenarDatosPropuesta(datosCotizacion);

    return cotizacion;
  }

  async agregarTokenAlState(): Promise<SeguroStore> {
    //this.store.clearState();
    let state = this.store.getState();

    // let resultadoToken = await this.loginService.obtenerToken();
    // this.store.setState({
    //   ...state,
    //   token: resultadoToken.token,
    // });
    // state = this.store.getState();
    return state;
  }

  procesarQueryParams() {
    this.route.queryParams.subscribe((params) => {
      this.cotizacionId = this.comunService.desencriptar(
        reemplazarTodo(params.a, " ", "+"),
        environment.cryptoKeyContinuarCotizacion
      );
      this.rutEjecutivo = this.comunService.desencriptar(
        reemplazarTodo(params.r, " ", "+"),
        environment.cryptoKeyContinuarCotizacion
      );
      this.rutEjecutivoDv = this.comunService.desencriptar(
        reemplazarTodo(params.d, " ", "+"),
        environment.cryptoKeyContinuarCotizacion
      );
      this.nombreEjecutivo = this.comunService.desencriptar(
        reemplazarTodo(params.n, " ", "+"),
        environment.cryptoKeyContinuarCotizacion
      );
      this.convenioId = parseInt(
        this.comunService.desencriptar(
          reemplazarTodo(params.c, " ", "+"),
          environment.cryptoKeyContinuarCotizacion
        )
      );
    });
  }

  redirectError(mensajeNoContinuable: string) {
    let url =
      "/error?t=" +
      encriptarParametrosUrl("Error") +
      "&m=" +
      encriptarParametrosUrl(mensajeNoContinuable);
    this.router.navigateByUrl(url);
  }

  private agregarVehiculoItemResumen(
    marca: string,
    modelo: string,
    anio: string
  ): void {
    const state = this.store.getState();
    const { datosVehiculo } = state.formulario.vehiculoForm;

    this.desplegableResumenService.agregarResumen({
      title: "Datos del vehículo",
      expanded: true,
      items: [
        {
          title: datosVehiculo.patente ? "Patente" : "Modelo",
          description: datosVehiculo.patente
            ? datosVehiculo.patente.toUpperCase()
            : "",
          subDescription: `
          ${marca} 
          ${modelo} 
          ${anio}`,
        },
      ],
    });
  }

  private agregarAseguradoItemResumen(): void {
    const state = this.store.getState();
    const { datosPersonales } = state.formulario.aseguradoForm;

    this.desplegableResumenService.agregarResumen({
      title: "Datos del asegurado",
      expanded: true,
      items: [
        {
          title: "Rut",
          description: format(datosPersonales.rut.toString().replace("-", ""), {
            dots: true,
          }),
          subDescription: `${datosPersonales.nombres} ${datosPersonales.apellidos}`,
        },
      ],
    });
  }

  private initDesplegableResumen(): void {
    this.desplegableResumenService.init({
      nameCurrentProcess: "Ingrese asegurado",
      resumen: [],
      numerocotizacion: "",
    });
  }

  llenarDatosFormulario(datosCotizacion: any) {
    FormularioNuevo.aseguradoForm.datosPersonales.rut = format(
      datosCotizacion.rutAsegurado.replace("-", ""),
      { dots: true }
    );
    FormularioNuevo.aseguradoForm.datosContacto.correo =
      datosCotizacion.correoAsegurado;
    FormularioNuevo.aseguradoForm.datosContacto.celular =
      datosCotizacion.celularAsegurado;
    FormularioNuevo.aseguradoForm.datosContacto.telefono =
      datosCotizacion.telefonoAsegurado;
    FormularioNuevo.aseguradoForm.datosPersonales.nombres =
      datosCotizacion.nombresAsegurado;
    FormularioNuevo.aseguradoForm.datosPersonales.apellidos =
      datosCotizacion.apellidoAsegurado;
    FormularioNuevo.vehiculoForm.datosVehiculo.patente =
      datosCotizacion.patente;

    FormularioNuevo.vehiculoForm.datosVehiculo.marcaId = datosCotizacion.marca;
    FormularioNuevo.vehiculoForm.datosVehiculo.modeloId =
      datosCotizacion.modelo;

    FormularioNuevo.vehiculoForm.datosVehiculo.anioId = datosCotizacion.anio;
    FormularioNuevo.vehiculoForm.datosVehiculo.usoId = datosCotizacion.uso;
    FormularioNuevo.vehiculoForm.datosVehiculo.estadoId =
      datosCotizacion.estado;
    FormularioNuevo.vehiculoForm.datosMotorChasis.tipoVehiculo =
      datosCotizacion.tipoVehiculo;
    FormularioNuevo.vehiculoForm.datosMotorChasis.numeroMotor =
      datosCotizacion.motor;
    FormularioNuevo.vehiculoForm.datosMotorChasis.numeroChasis =
      datosCotizacion.chasis;
    FormularioNuevo.vehiculoForm.datosMotorChasis.kilometraje =
      datosCotizacion.kilometraje;
    FormularioNuevo.vehiculoForm.datosVehiculo.MarcaAs400 =
      datosCotizacion.marcaAs400;
    FormularioNuevo.vehiculoForm.datosVehiculo.ModeloAs400 =
      datosCotizacion.modeloAs400;

    FormularioNuevo.contratanteForm.datosPersonales.nombres =
      datosCotizacion.nombresContratante;
    FormularioNuevo.contratanteForm.datosPersonales.apellidos =
      datosCotizacion.apellidoContratante;
    FormularioNuevo.contratanteForm.datosPersonales.rut =
      datosCotizacion.rutContratante;
    FormularioNuevo.contratanteForm.datosContacto.correo =
      datosCotizacion.correoContratante;

    FormularioNuevo.contratanteForm.datosUbicacion.direccion =
      datosCotizacion.direccionContratante;
    FormularioNuevo.contratanteForm.datosUbicacion.comunaId =
      datosCotizacion.comunaContratante;
    FormularioNuevo.contratanteForm.datosUbicacion.regionId =
      datosCotizacion.regionContratante;

    FormularioNuevo.contratanteForm.detallesPago.formaPagoId =
      datosCotizacion.formaPago;
    FormularioNuevo.contratanteForm.detallesPago.numeroCuotasId =
      datosCotizacion.numeroCuotas;
    FormularioNuevo.contratanteForm.datosMotorChasis.numeroMotor =
      datosCotizacion.motor;
    FormularioNuevo.contratanteForm.datosMotorChasis.numeroChasis =
      datosCotizacion.chasis;
  }

  llenarDatosVehiculo(datosCotizacion: any) {
    let vehiculo = new VehiculoModel();
    vehiculo.Anio = FormularioNuevo.vehiculoForm.datosVehiculo.anioId;
    vehiculo.Color = datosCotizacion.color;
    vehiculo.Marca = FormularioNuevo.vehiculoForm.datosVehiculo.marcaId;
    vehiculo.Modelo = FormularioNuevo.vehiculoForm.datosVehiculo.modeloId;
    vehiculo.MarcaAs400 = FormularioNuevo.vehiculoForm.datosVehiculo.MarcaAs400;
    vehiculo.ModeloAs400 =
      FormularioNuevo.vehiculoForm.datosVehiculo.ModeloAs400;
    vehiculo.Estado =
      FormularioNuevo.vehiculoForm.datosVehiculo.estadoId?.toString();
    vehiculo.Patente = FormularioNuevo.vehiculoForm.datosVehiculo.patente;
    vehiculo.Uso = FormularioNuevo.vehiculoForm.datosVehiculo.usoId?.toString();
    vehiculo.Estado = FormularioNuevo.vehiculoForm.datosVehiculo.estadoId;
    vehiculo.Motor = FormularioNuevo.vehiculoForm.datosMotorChasis.numeroMotor;
    vehiculo.Chasis =
      FormularioNuevo.vehiculoForm.datosMotorChasis.numeroChasis;
    vehiculo.TipoVehiculo =
      FormularioNuevo.vehiculoForm.datosMotorChasis.tipoVehiculo;
    vehiculo.Kilometraje =
      FormularioNuevo.vehiculoForm.datosMotorChasis.kilometraje;

    vehiculo.MarcaDesc = datosCotizacion.marcaDescripcion;
    vehiculo.ModeloDesc = datosCotizacion.modeloDescripcion;

    return vehiculo;
  }

  llenarDatosPropuesta(datosCotizacion: any) {
    if (datosCotizacion?.numeroCuotas) {
      let formaPago = new FormaPagoModel();

      formaPago.CantidadCuotas = datosCotizacion?.numeroCuotas;
      formaPago.FormaPagoId = datosCotizacion?.formaPago;
      formaPago.TarifaCuota = datosCotizacion?.tarifaCuota;
      formaPago.TarifaCuotaUf = datosCotizacion?.tarifaCuotaUf;
      formaPago.MandatoId = datosCotizacion?.idMandato;

      return formaPago;
    }

    return undefined;
  }
}
