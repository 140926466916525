import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { URL_TOKEN } from '@utils/url.util';
import { Store } from './store.service';
import { SeguroStore } from '@interfaces/seguro-store.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private httpClient2: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private store: Store<SeguroStore>,
    handler: HttpBackend
  ) {
    this.httpClient2 = new HttpClient(handler);
  }

  async obtenerToken(): Promise<any> {
    return this.httpClient.get<any>(URL_TOKEN + '/Authenticate').toPromise();
  }

  async obtenerTokenResolver(): Promise<any> {
    return this.httpClient2
      .get<any>(environment.UrlBase + '/Login/Authenticate', {
        headers: new HttpHeaders({ APP: environment.IdApp.toString() }),
      })
      .pipe(
        tap((response: any) => {
          let state = this.store.getState();
          this.store.setState({
            ...state,
            token: response.token,
          });
        })
      )
      .toPromise();
  }
}
