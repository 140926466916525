import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatosConvenio, SeguroStore } from '@interfaces/seguro-store.interface';
import { URL_CONVENIO } from '@utils/url.util';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from './store.service';
import { Observable, forkJoin, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConvenioService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<SeguroStore>
  ) {}

  async obtenerConvenioPorId(id: string): Promise<DatosConvenio> {
    let params = new HttpParams().set('id', id);
    return this.httpClient
      .get<any>(URL_CONVENIO + '/ObtenerConvenio', { params })
      .toPromise();
  }

  obtenerParametrosDelConvenio(id: string) {
    return this.httpClient
      .get<DatosConvenio>(`${URL_CONVENIO}/ObtenerConvenio`, {
        params: {
          id,
        },
      })
      .pipe(
        switchMap((convenioResponse: DatosConvenio) => {
          if (convenioResponse.enableRecaptchaEnterprise) {
            return forkJoin([
              of(convenioResponse),
              this.agregarScriptReCaptchaAlIndexHtml(convenioResponse),
            ]);
          }
          return forkJoin([of(convenioResponse), of(null)]);
        }),
        map((convenioResponse) => {
          if (convenioResponse[0] === null) {
            throw new Error('Convenio no encontrado');
          }
          const state = this.store.getState();
          this.store.setState({
            ...state,
            datosConvenio: convenioResponse[0],
          });
          return convenioResponse[0];
        }),
        catchError((e: HttpErrorResponse) => {
          return throwError(e);
        })
      );
  }

  agregarScriptReCaptchaAlIndexHtml(convenioResponse: DatosConvenio) {
    return new Observable((observer) => {
      if (!convenioResponse.enableRecaptchaEnterprise) {
        observer.next(true);
        observer.complete();
        return;
      }

      let script: HTMLScriptElement | null = document.querySelector(
        'script[src*="https://www.google.com/recaptcha/enterprise.js"]'
      );
      if (!script) {
        script = document.createElement('script');
        script.src =
          'https://www.google.com/recaptcha/enterprise.js?render=' +
          convenioResponse.reCaptchaEnterprisePublicKey;
        script.async = false;
        script.onload = () => {
          observer.next(true);
          observer.complete();
        };
        document.head.appendChild(script);
      }
    });
  }
}
